import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import bg from '../images/cspic.png';
import logo from '../images/logo-white.png';
import insta from '../images/instagram.png';
import telegram from '../images/telegram.png';
import linkedin from '../images/linkedin.png';
import twitter from '../images/twitter.png';
import ReactGA from 'react-ga';


const trackingId = 'G-QEVNTN0BYG'; // Google Analytics tracking ID


const Home = () => {

  useEffect(() => {
    ReactGA.initialize(trackingId);
  }, []);

  const handleSocialClick = (socialPlatform) => {
    ReactGA.event({
      category: 'Social Media',
      action: `Clicked ${socialPlatform}`,
      label: socialPlatform,
    });
  };


  return (
    <div>
      <Helmet>
        <title>Surfheim VPN | Secure Your Data and Identity</title>
        <meta name="description" content="Get the best VPN services to secure your online identity and browse anonymously." />
        <meta name="keywords" content="vpn, virtual private network, online security, anonymous browsing, secure internet, fast vpn, cheap vpn, best vpn, secure vpn, xray, v2ray, nordvpn, express vpn, free vpn, best vpn service, vpn for streaming, vpn comparison" />
        <meta property="og:title" content="Best VPN Services - Secure Your Online Identity" />
        <meta property="og:description" content="Get the best VPN services to secure your online identity and browse anonymously." />
        <meta property="og:image" content="url_to_image" />
      </Helmet>

      <div className='h-screen w-full bg-gradient-to-b from-accent to-accent/15'>
        <div className=' flex flex-col items-center'>
        
          <div className='size-[28%] md:size-[20%] lg:size-[14%] xl:size-[10%] mx-auto h-full pt-16 sm:pt-12 md:pt-10 lg:pt-8'>
            <a href='/'> <img src={logo} alt='logo' /> </a>
          </div>

          <div className='size-4/5 pt-32 lg:size-3/5 xl:size-2/5'>
            <img src={bg} alt='coming-soon' />
          </div>

          <div className='flex flex-col pt-6 text-center'>
            
            <h1 className='text-[20px] lg:text-[25px] text-accentHover/65 font-bold pt-10 md:pt-12 xl:pt-16'>STAY TUNED</h1>
            <h1 className='text-[15px] lg:text-[20px] text-accent font-semibold pt-4'>Follow us on social media:</h1>
          </div>

          <div className='flex flex-row gap-x-4 pt-2'>
            <a href='https://www.instagram.com/surfheim?igsh=dGl5bGhzazZ5aGtv' onClick={() => handleSocialClick('Instagram')}><img className='w-8 h-8' src={insta} alt='Instagram' /></a>
            <a href='https://t.me/surfheim' onClick={() => handleSocialClick('Telegram')}><img className='w-8 h-8' src={telegram} alt='Telegram' /></a>
            <a href='https://x.com/surfheim' onClick={() => handleSocialClick('Twitter')}><img className='w-8 h-8' src={twitter} alt='X' /></a>
            <a href='https://www.linkedin.com/company/surfheim/' onClick={() => handleSocialClick('LinkedIn')}><img className='w-8 h-8 rounded-sm' src={linkedin} alt='LinkedIn' /></a>
          </div>


          <div className='flex flex-col mx-auto text-center pt-44 sm:pt-36 md:pt-32 xl:pt-28'>

            <hr className='border-dark'/>

            <div className='flex flex-col md:flex-row sm:gap-x-16 md:gap-x-28 lg:gap-x-36 px-8 sm:px-4'>
              <div className='font-primary font-semibold pt-2 text-dark/90'>
                Contact: info@surfheim.com
              </div>
              <div className='pt-2 font-semibold text-dark/90'>
                Copyright  &copy; {new Date().getFullYear()} - All Rights Reserved.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  )
  
};

export default Home;