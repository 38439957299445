import React from 'react';
import Home from './pages/Home';


const App = () => {
 
  return (
      <Home />
 
  );
};

export default App;
